/* # Base */

/*****************/
/******HTML5 Elements*****/
/*****************/
@at-root{
	
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	
	
	html {
		font-size: 62.5%;
		position: relative;
		color:$black;
		-webkit-font-smoothing: antialiased;
	}
	
	
	*, *::before, *::after {
		background-repeat: no-repeat;
	}
	
	
	h1,h2,h3,h4 {
		margin: 0;
	}
	
	
	p {
		margin:0;
	}
	
	
	svg,img {
		width: 100%;
		max-width:100%;
	}
	
	
	ul {
		margin:0;
		padding:0;
	}
	
	
	li {
		list-style: none
	}
	
	
	a {
		text-decoration:none;
		color: inherit;
		
		&:hover{
			text-decoration: none;
		}
	}
		
	button:focus,
	input:focus {
		outline:0;
	}
}

html{
	max-height: 100vh;
	max-width: 100vw;
	overflow: hidden;
	min-width: 320px;
} 

body {
	width: 100%;
	height: 100%;
	overflow: hidden;
	@include font-style($font-circular-bold, 18px, 100, bold, 1);
}

$color-primary : #3c3c3c;
$color-secondary : #f4f4f4;

/*****************/
/****** USEFUL CLASSES *****/
/*****************/

@mixin useful-classes {
	#{if(&, "&", "*")}-overflow {
		overflow:hidden;
	}

	#{if(&, "&", "*")}-uppercase {
		text-transform:uppercase;
	}
}
@include useful-classes;