@mixin gradient-animation($start, $end, $transTime) {
	background-size: 100%;
	background-image: -webkit-linear-gradient($start, $end);
	background-image: linear-gradient($start, $end);
	position: relative;
	z-index: 100;

	&:before {
		border-radius: inherit;
		background: #E62140;
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: -100;
		transition: opacity $transTime ease-in-out;
	}

	@for $i from 1 through 100 {
		&.visible#{$i} {
			&:before {
				opacity: $i/100
			}
		}
	}
}

.homepage {
	position: relative;

	&.landscape_ratio {
		@include gradient-animation(#FFCA30, #E62140 100%, 1s);
	}

	&.portrait_ratio {
		@include gradient-animation(rgb(182, 182, 182), #E62140 100%, 1s);
	}

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: url('../../cursor.png'), pointer;


	&:before {
		border-radius: inherit;
		background-color: #E62140;
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		z-index: -1;
	}

}

svg {
	height: 100vh;
	width: 100vw;
}

.bm {
	position: absolute;
	z-index: -1;
	transform: scale(0);
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	will-change: transform;

	svg {
		max-width: 1600px;
	}
}

.wording {
	position: absolute;
	color: white;
	transform-origin: 50% 50%;
	font-weight: bold;
	font-size: 1.5em;
	user-select: none;
}

#content {
	z-index: -1;
	transform: scale(0);
}

.click {
	animation: clickTilt 0.3s linear infinite;
}

@keyframes clickTilt{
	0% {opacity: 0;} 
	50% {opacity: 1;} 
	100% {opacity: 0;} 
}

.wordOnSuccess {
	position: absolute;
	left: 0;
	top: 0;
	width: 1em;
	height: 1em;
	color: white;
	transform-origin: center;
	will-change: transform;
	user-select: none;
	opacity: 0;
}
