/*****************/
/****** Text styles *****/
/*****************/

@mixin text-styles {
	#{if(&, "&", "*")}-underline {
		display: inline-block;
		position: relative;

		&:after {
			content:'';
			position: absolute;
			left: 0;
			bottom: -1rem;
			width: 100%;
			height: 0.8rem;
			background-color: $white;
		}
	}
}
@include text-styles;